import React from "react";
import { Provider } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { createStore, combineReducers } from "redux";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";

const firebaseConfig = {
  apiKey: "AIzaSyASM_PUbiqJebHMTT81nU7SpOYXXdnMRGY",
  authDomain: "my-new-colors.firebaseapp.com",
  databaseURL: "https://my-new-colors.firebaseio.com",
  projectId: "my-new-colors",
  storageBucket: "my-new-colors.appspot.com",
  messagingSenderId: "581891181929",
  appId: "1:581891181929:web:ade519b04d73aef1a281f8",
  measurementId: "G-M47Q3RKF9D",
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore().enablePersistence(); // <- needed if using firestore

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const MyNewColorsStore = (props) => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      {props.children}
    </ReactReduxFirebaseProvider>
  </Provider>
);

export default MyNewColorsStore;
