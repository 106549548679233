import React, { useState } from 'react';
import {
    IonHeader,
    IonToolbar,
    IonPage,
    IonTitle,
    IonButtons,
    IonBackButton,
    IonContent,
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
    IonInput,
    IonTextarea,
    IonItemDivider,
    IonCheckbox,
    IonRange,
} from '@ionic/react';
import { save } from 'ionicons/icons';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, RootStateOrAny } from 'react-redux';
import { get as _get, sortBy as _sortBy } from 'lodash';
import Trait from 'models/Trait';
import firestoreToArray from 'utils/firestoreToArray';

const NewProspect: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    } as any);

    useFirestoreConnect({
        collection: 'traits'
    });

    let traits = useSelector((state: RootStateOrAny) => firestoreToArray<any>(_get(state, 'firestore.data.traits')));

    const updateFormGenerator = (trait: Trait) => ((event: any): void => {
        const formDataCopy: any = {...formData};
        formDataCopy[trait.name] = Object.assign({}, formDataCopy[trait.name]);
        formDataCopy[trait.name].value = event.target.value;
        formDataCopy[trait.name].checked = true;

        setFormData(formDataCopy);
    });

    const checkedChangedGenerator = (trait: Trait) => ((event: any): void => {
        const formDataCopy: any = {...formData};
        formDataCopy[trait.name] = Object.assign({}, formDataCopy[trait.name]);
        formDataCopy[trait.name].checked = event.target.checked;

        if (!formDataCopy[trait.name].value) {
            formDataCopy[trait.name].value = 5;
        }

        setFormData(formDataCopy);
    });

    let lastCategory: string = '';

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="tertiary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/prospects" />
                    </IonButtons>
                    <IonTitle>New Prospect</IonTitle>
                    <IonButtons slot="end">
                        <IonButton>
                            <IonIcon slot="icon-only" icon={save} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonItem>
                    <IonLabel position="floating">Name</IonLabel>
                    <IonInput name='name' value={formData.name.value} onIonChange={updateFormGenerator(_get(traits, 'name', {}))} required={true} />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Description</IonLabel>
                    <IonTextarea name='description' value={formData.description.value} onIonChange={updateFormGenerator(_get(traits, 'description', {}))} ></IonTextarea>
                </IonItem>
                {traits && _sortBy(traits, ['category', 'name']).filter((trait: Trait) => !trait.special).map((trait: Trait) => {
                    let returnVal = null;
                    if (trait.category !== lastCategory) {
                        returnVal = <IonItemDivider >
                            <IonLabel>{trait.category.toUpperCase()}</IonLabel>
                        </IonItemDivider>;

                        lastCategory = trait.category;
                    }

                    const traitName = trait.name;
                    let data = formData[traitName];

                    return (
                        <>
                            {returnVal}
                            <IonItem key={trait.name}>
                                <IonLabel>{trait.name}</IonLabel>
                                <IonCheckbox
                                    color="primary"
                                    slot="start"
                                    checked={_get(data, 'checked', false)}
                                    onIonChange={checkedChangedGenerator(trait)}
                                />
                                <IonRange
                                    min={1}
                                    max={10}
                                    pin
                                    step={1}
                                    snaps={true}
                                    color="primary"
                                    value={_get(data, 'value', 5)}
                                    onIonChange={updateFormGenerator(trait)}
                                >
                                    <IonLabel slot="start">1</IonLabel>
                                    <IonLabel slot="end">10</IonLabel>
                                </IonRange>
                            </IonItem>
                        </>
                    );
                })}
                <IonItemDivider />
            </IonContent>
        </IonPage>
    );
};

export default NewProspect;
