import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonButton,
  IonIcon,
  IonSearchbar,
} from "@ionic/react";
import "./Prospects.css";

import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector, RootStateOrAny } from "react-redux";

import { get as _get, noop as _noop } from "lodash";
import firestoreToArray from "../../../utils/firestoreToArray";
import Prospect from "../../../models/Prospect";

import { add, filter } from "ionicons/icons";
import { useHistory } from "react-router";

const Prospects: React.FC = () => {
  const auth = useSelector((state: RootStateOrAny) => state.firebase.auth);
  const history = useHistory();

  useFirestoreConnect({
    collection: `users`,
    doc: auth.uid,
    subcollections: [
      {
        collection: "prospects",
        storeAs: "prospects",
      },
    ],
  });

  let prospects = useSelector((state: RootStateOrAny) => {
    return firestoreToArray<any>(
      _get(state, `firestore.data.users[${auth.uid}].prospects`)
    );
  });

  function addProspect() {
    history.push("/prospects/new");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="primary">
            <IonButton onClick={_noop}>
              <IonIcon slot="icon-only" icon={filter} />
            </IonButton>
            <IonButton onClick={addProspect}>
              <IonIcon slot="icon-only" icon={add} />
            </IonButton>
          </IonButtons>
          <IonTitle>Prospects</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Prospects</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar />
        <IonList>
          {prospects &&
            prospects.map((prospect: Prospect) => (
              <IonItem href={`/prospects/${prospect.uid}`} key={prospect.uid}>
                <IonLabel>{prospect.name}</IonLabel>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Prospects;
