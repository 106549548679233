import React from "react";
import { Route, Redirect } from "react-router";
import { useSelector, RootStateOrAny } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const auth = useSelector((state: RootStateOrAny) => state.firebase.auth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
