import React from "react";
import { isLoaded } from "react-redux-firebase";
import { useSelector, RootStateOrAny } from "react-redux";

function AuthIsLoaded(props: any) {
  const auth = useSelector((state: RootStateOrAny) => state.firebase.auth);

  if (!isLoaded(auth)) return <span className="splash">Loading...</span>;
  return props.children;
}

export default AuthIsLoaded;
