import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/react";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router";

import "./Account.css";

const Account: React.FC = () => {
  const firebase = useFirebase();
  const auth = useSelector((state: RootStateOrAny) => state.firebase.auth);
  const history = useHistory();

  function logout() {
    firebase.logout();
    history.push("/login");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary">
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel>Display Name: {auth.displayName}</IonLabel>
        </IonItem>
        <IonButton
          className="logoutButton"
          color="danger"
          expand="block"
          onClick={logout}
        >
          Log Out
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Account;
