import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonLabel,
} from "@ionic/react";
import { useFirestoreConnect } from "react-redux-firebase";

import Term from "../../../models/Term";

const Glossary: React.FC = (props) => {
  useFirestoreConnect({
    collection: "glossary",
  });

  const glossary = useSelector(
    (state: RootStateOrAny) => state.firestore.data.glossary
  );

  let values: any[] = [],
    keys: (string | number | undefined)[] = [];

  if (glossary) {
    values = Object.values(glossary);
    keys = Object.keys(glossary);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonTitle>Glossary</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {values &&
            keys &&
            values.map((term: Term, index: number) => (
              <IonItem
                button
                detail
                href={`/glossary/term/${keys[index]}`}
                key={keys[index]}
                onClick={() => {}}
              >
                <IonLabel>{term.term}</IonLabel>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Glossary;
