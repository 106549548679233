import React from "react";
import { IonBadge, IonItem, IonLabel, IonItemSliding, IonItemOptions, IonItemOption, IonIcon } from "@ionic/react";
import { pencil, trash } from 'ionicons/icons';

interface TraitNumberListItemProps {
  name: string;
  value: number | string;
}

function getColorFromValue(value: React.ReactText) {
  let color = "success";
  if (value < 8) color = "warning";
  if (value < 5) color = "danger";
  return color;
}

const TraitNumberListItem: React.FC<TraitNumberListItemProps> = ({
  name,
  value,
}) => {
  let color = getColorFromValue(value);

  return (
    <IonItemSliding>
      <IonItem>
        <IonLabel>{name}</IonLabel>
        <IonBadge color={color} slot="end">
          {value}
        </IonBadge>
      </IonItem>
      <IonItemOptions>
        <IonItemOption color="primary">
          <IonIcon slot="start" icon={pencil}></IonIcon>
          Edit
        </IonItemOption>
        <IonItemOption color="danger">
          <IonIcon slot="start" icon={trash} />
          Delete
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>

  );
};

export default TraitNumberListItem;
