import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonFabList,
  IonModal,
} from "@ionic/react";
import React, { useState } from "react";
import Prospect from "../../../../models/Prospect";
import Rating from '../../.././../models/Rating';
import TraitNumberListItem from 'components/TraitNumberListItem';
import { add, starHalf } from 'ionicons/icons';
import ProspectSegmentBar from './ProspectSegmentBar';
import ProspectNewRating from './ProspectNewRating';

interface ProspectPageProps {
  prospect: Prospect;
}

function getActiveSegment(segment: string = "score", ratings: Rating[] = []): any {
  return (
    ratings && ratings.filter((rating: Rating) => rating.trait?.category === segment).map((rating: Rating) => <TraitNumberListItem name={rating.name} value={rating.value} />)
  );
}

const ProspectPage: React.FC<ProspectPageProps> = ({ prospect }) => {
  const [segment, setSegment] = useState("score");
  const [showModal, setShowModal] = useState(false);

  let activeSegment = getActiveSegment(segment, prospect.ratings);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/prospects" />
          </IonButtons>
          <IonTitle>{prospect.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={add} />
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton onClick={() => setShowModal(true)}><IonIcon icon={starHalf} /></IonFabButton>
          </IonFabList>
        </IonFab>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{prospect.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ProspectSegmentBar segment={segment} setSegment={setSegment} />
        {activeSegment}
        <IonModal isOpen={showModal} cssClass='my-custom-class'>
          <ProspectNewRating setShowModal={setShowModal} />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default ProspectPage;
