import React from "react";
import { IonPage, IonHeader, IonToolbar, IonTitle } from "@ionic/react";

const Home: React.FC = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>
    </IonPage>
  );
};

export default Home;
