import { get as _get } from "lodash";
import Prospect from 'models/Prospect';
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";
import firestoreToArray from 'utils/firestoreToArray';
import "./Prospect.css";
import ProspectPage from './ProspectPage';

const ProspectContainer: React.FC = () => {
  const auth = useSelector((state: RootStateOrAny) => state.firebase.auth);
  const { id } = useParams();

  useFirestoreConnect({
    collection: `users`,
    doc: auth.uid,
    storeAs: "users",
    subcollections: [
      {
        collection: "prospects",
        storeAs: "prospects",
        doc: id,
      },
    ],
  });

  useFirestoreConnect({
    collection: "ratings",
    where: [['prospect', '==', id]]
  });

  useFirestoreConnect({
    collection: "traits"
  });

  const prospect = useSelector((state: RootStateOrAny) => {
    return _get(
      state,
      `firestore.data.users`,
      {}
    );
  });

  const traits = useSelector((state: RootStateOrAny) => {
    return _get(
      state,
      `firestore.data.traits`,
      {}
    );
  });

  const ratings = useSelector((state: RootStateOrAny) => {
    const innerRatings = firestoreToArray(_get(
      state,
      'firestore.data.ratings',
      []
    ));

    if (traits && Object.keys(traits).length > 0) {
      innerRatings.forEach((rating: any) => {
        if (rating.traitId) {
          rating.trait = traits[rating.traitId];
        }
      });
    }

    return innerRatings;
  });

  let mergedProspect: Prospect = Object.assign({}, prospect, { ratings: ratings });

  return (
    <ProspectPage prospect={mergedProspect} />
  );
};

export default ProspectContainer;
