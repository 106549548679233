import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { book, home, people, person, bulb } from "ionicons/icons";
import Prospects, { ProspectContainer } from "./pages/app/Prospects";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import PropTypes from "prop-types";
import Glossary, { Term } from "./pages/app/Glossary";
import Account from "./pages/app/Account";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/app/Login";
import AuthIsLoaded from "./components/AuthIsLoaded";
import Home from "./pages/app/Home";
import NewProspect from "./pages/app/NewProspect";
import AdviceList, { Advice } from "pages/app/AdviceList";

const App: React.FC = (props) => (
  <IonApp>
    <IonReactRouter>
      <AuthIsLoaded>
        <IonTabs>
          <IonRouterOutlet>
            <Route component={Login} path="/login" exact={true} />
            <PrivateRoute path="/home" exact={true}>
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/prospects" exact={true}>
              <Prospects />
            </PrivateRoute>
            <PrivateRoute path="/prospects/:id" exact={true}>
              <ProspectContainer />
            </PrivateRoute>
            <PrivateRoute path="/prospects/new" exact={true}>
              <NewProspect />
            </PrivateRoute>
            <PrivateRoute path="/advicelist" exact={true}>
              <AdviceList />
            </PrivateRoute>
            <PrivateRoute path="/advice" exact={true}>
              <Advice />
            </PrivateRoute>
            <PrivateRoute path="/glossary" exact={true}>
              <Glossary />
            </PrivateRoute>
            <PrivateRoute path="/glossary/term/:id" exact={true}>
              <Term />
            </PrivateRoute>
            <PrivateRoute path="/account" exact={true}>
              <Account />
            </PrivateRoute>
            <Route
              path="/"
              render={() => <Redirect to="/prospects" />}
              exact={true}
            />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={home} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="prospects" href="/prospects">
              <IonIcon icon={people} />
              <IonLabel>Prospects</IonLabel>
            </IonTabButton>
            <IonTabButton tab="advice" href="/advicelist">
              <IonIcon icon={bulb} />
              <IonLabel>Advice</IonLabel>
            </IonTabButton>
            <IonTabButton tab="glossary" href="/glossary">
              <IonIcon icon={book} />
              <IonLabel>Glossary</IonLabel>
            </IonTabButton>
            <IonTabButton tab="account" href="/account">
              <IonIcon icon={person} />
              <IonLabel>Account</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </AuthIsLoaded>
    </IonReactRouter>
  </IonApp>
);

App.propTypes = {
  loggedIn: PropTypes.bool,
};

export default App;
