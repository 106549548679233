import Trait from 'models/Trait';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';

interface TraitSelectorProps {
  setSelectedTraits(value: any): any;
  selectedTraits: string[];
  traits: Trait[];
  selectedCategories: string[];
}

const TraitSelector: React.FC<TraitSelectorProps> = ({ setSelectedTraits, selectedTraits, traits, selectedCategories }) => <IonItem>
  <IonLabel>Trait</IonLabel>
  <IonSelect
    multiple={true}
    placeholder="Select One Or More"
    onIonChange={e => setSelectedTraits(e.detail.value)}
    value={selectedTraits}
  >
    {traits && getTraits(traits, selectedCategories).map(trait => <IonSelectOption value={trait.uid}>{trait.name}</IonSelectOption>)}
  </IonSelect>
</IonItem>;

function getTraits(traits: Trait[], selectedCategories: string[]): Trait[] {
  if (selectedCategories && selectedCategories.length) {
    return traits.filter((trait: Trait) => selectedCategories.indexOf(trait.category) >= 0);
  }

  return traits;
}

export default TraitSelector;
