import React from "react";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import "./Term.css";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";
import { get as _get } from "lodash";

const Term: React.FC = (props) => {
  const { id } = useParams();

  useFirestoreConnect({
    collection: "glossary",
  });

  let term = useSelector((state: RootStateOrAny) =>
    _get(state, `firestore.data.glossary[${id}]`)
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="tertiary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{_get(term, "term", "")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <p>{_get(term, "definition", "")}</p>
      </IonContent>
    </IonPage>
  );
};

export default Term;
