import React from "react";
import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';

interface ProspectSegmentBarProps {
  segment: string;
  setSegment(value: any): any;
}

const buttons = [{
  value: "score",
  label: "Score"
}, {
  value: "physical",
  label: "Physical"
}, {
  value: "personality",
  label: "Personality"
}, {
  value: "health",
  label: "Health"
}, {
  value: "skills",
  label: "Skills"
}]

const ProspectSegmentBar: React.FC<ProspectSegmentBarProps> = ({ segment, setSegment }) => {
  return (
    <IonSegment onIonChange={e => setSegment(e.detail.value as any)} value={segment}>
      {buttons && buttons.map(button => (
        <IonSegmentButton value={button.value}>
          <IonLabel>{button.label}</IonLabel>
        </IonSegmentButton>
      ))}
    </IonSegment>
  );
};

export default ProspectSegmentBar;
