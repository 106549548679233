import React from "react";
import { IonPage, IonIcon, IonContent, IonButton } from "@ionic/react";
import { logoGoogle as google } from "ionicons/icons";
import { useFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector, RootStateOrAny } from "react-redux";
import { useLocation, Redirect } from "react-router";

const Login: React.FC = (props) => {
  const firebase = useFirebase();
  const auth = useSelector((state: RootStateOrAny) => state.firebase.auth);
  let location = useLocation();

  function loginWithGoogle() {
    return firebase.login({ provider: "google", type: "popup" });
  }

  return (
    <IonPage>
      <IonContent>
        <h1>Welcome To "My New Colors"</h1>
        {!isLoaded(auth) ? (
          <span>Loading...</span>
        ) : isEmpty(auth) ? (
          // <GoogleButton/> button can be used instead
          <IonButton color="danger" onClick={loginWithGoogle}>
            <IonIcon icon={google} slot="start" />
            Login with Google
          </IonButton>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Login;
