export default function firestoreToArray<T>(firestoreObject: object): T[] {
  if (!firestoreObject) {
    return [];
  }

  const returnVal: T[] = [];

  const values = Object.values(firestoreObject);
  const keys = Object.keys(firestoreObject);

  for (let index = 0; index < values.length; index++) {
    const newEntry = { ...values[index] };
    newEntry.uid = keys[index];

    returnVal.push(newEntry);
  }

  return returnVal;
}
