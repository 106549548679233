import React from "react";
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';

interface CategorySelectorProps {
  setSelectedCategories(value: any): any;
  selectedCategories: string[];
  categoryOptions: string[];
}

const CategorySelector: React.FC<CategorySelectorProps> = ({ setSelectedCategories, selectedCategories, categoryOptions }) => {

  return (<IonItem>
    <IonLabel>Category</IonLabel>
    <IonSelect
      multiple={true}
      placeholder="Select One Or More"
      onIonChange={e => setSelectedCategories(e.detail.value)}
      value={selectedCategories}
    >
      {categoryOptions && categoryOptions.map(category => <IonSelectOption key={category} value={category}>{category}</IonSelectOption>)}
    </IonSelect>
  </IonItem>);
};

export default CategorySelector;
