import React, { useState } from 'react';
import { IonIcon, IonButton, IonContent, IonToolbar, IonButtons, IonLabel, IonList } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, RootStateOrAny } from 'react-redux';

import { get as _get, uniq as _uniq } from 'lodash';
import firestoreToArray from 'utils/firestoreToArray';
import Trait from 'models/Trait';
import CategorySelector from './CategorySelector';
import TraitSelector from "./TraitSelector";

interface ProspectNewRatingProps {
  setShowModal(value: boolean): any;
}

const ProspectNewRating: React.FC<ProspectNewRatingProps> = ({ setShowModal }) => {

  useFirestoreConnect({
    collection: 'traits',
    orderBy: ['category', 'asc']
  });

  const traits = useSelector((state: RootStateOrAny) => {
    return firestoreToArray<Trait>(_get(
      state,
      'firestore.data.traits',
      {}
    ));
  });

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTraits, setSelectedTraits] = useState([]);

  let categoryOptions: string[] = [];

  if (traits) {
    traits.forEach(trait => {
      categoryOptions.push(trait.category);
    });

    categoryOptions = _uniq(categoryOptions);
  }

  return (
    <IonContent>
      <IonToolbar color="tertiary">
        <IonButtons slot="start">
          <IonButton onClick={() => setShowModal(false)}>
            <IonIcon icon={arrowBack} />
          </IonButton>
        </IonButtons>
        <IonLabel>New Rating</IonLabel>
      </IonToolbar>
      <CategorySelector setSelectedCategories={setSelectedCategories} selectedCategories={selectedCategories} categoryOptions={categoryOptions} />
      <TraitSelector setSelectedTraits={setSelectedTraits} selectedCategories={selectedCategories} traits={traits} selectedTraits={selectedTraits} />
      <IonList>
        
      </IonList>
      <IonButton>Cancel</IonButton>
      <IonButton color="success">Save</IonButton>
    </IonContent>
  );
};

export default ProspectNewRating;
